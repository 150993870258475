<form
  class="pu-tfa__body"
  [formGroup]="form"
  (ngSubmit)="reset()"
>

  <pu-icon
    iconName="p"
    size="96"
  />

  <h4
    class="pu-tfa__header"
    translate="tfaReset.header"
  >
  </h4>

  <p
    class="pu-tfa__message"
    translate="tfaReset.message1"
  >
  </p>

  <pu-simple-input
    class="pu-form-container"
    formControlName="code"
    [errorDictionary]="errorDictionary"
    [label]="'tfaReset.control.code' | translate"
  />

  <div class="pu-tfa__actions">
    <button
      type="submit"
      puButton
      size="l"
      uiType="primary"
      [disabled]="!form.valid || isLoading"
      translate="tfa.btn.verify"
    >
    </button>
  </div>

  <p
    class="pu-tfa__message"
    [innerHTML]="'tfaReset.help' | translate"
  >
  </p>
</form>

export const accessDataMock = {
  permissions: [
    'Admin',
    'AdminUsers',
    'AdminUsersGroups',
    'AdminUsersRoles',
    'AdminUsersCabinetIds',
    'AdminUsersLogins',
    'AdminUsersStatuses',
    'AdminUsersBalance',
    'AdminUsersFunctionalBalance',
    'AdminUsersAchievementsHistory',
    'AdminUsers2FAForUsers',
    'AdminTransactionsFunctional',
    'AdminMessageHubLearningAndDevelopmentMessages',
    'AdminMessageHubLearningAndDevelopmentMessage',
    'AdminPinCoins',
    'AdminPinCoinsAutomaticAccrual',
    'AdminPinCoinsEventRewards',
    'AdminProducts',
    'AdminProductsEditable',
    'AdminLocations',
    'AdminOrders',
    'AdminNews',
    'AdminFAQ',
    'AdminP2P&Limits',
    'AdminQuests',
    'AdminQuestCatalog',
    'AdminSkins',
    'AdminSupport',
    'AdminAchievements',
    'AdminCourses',
    'AdminCoursesAward',
    'AdminCalendar',
    'AdminChannels',
    'AdminTemplates',
    'AdminCongrats',
    'AdminOnboarding',
    'AdminPpWards',
    'AdminClubs',
    'AdminAnalytics',
    'user',
  ],
};

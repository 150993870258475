import { inject } from '@angular/core';
import { HttpClient, HttpContextToken, HttpHandlerFn, HttpRequest } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { switchMap, tap, throwError } from 'rxjs';
import { WINDOW } from '@pinup-teams/common';
import { environment } from '@pt/environment';
import { AuthActions } from '@pt/auth';
import { RootState } from '@pt/store';
import { AccessData } from '@pt/models';
import { TimeDeltaService } from '@pt/services';

import { AuthService } from '../auth.service';
import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../auth.constants';

export const DISABLE_REFRESH_TOKEN_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

/**
 * Extra time to be sure that request won't go to the server where token is already expired
 */
const extraTime = 10000;

/**
 * Refresh token interceptor
 */
export function refreshTokenInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const window = inject(WINDOW);
  const httpClient = inject(HttpClient);
  const authService = inject(AuthService);
  const store = inject(Store<RootState>);
  const timeDeltaSubject = inject(TimeDeltaService);

  if (ignoreRefreshToken(request)) {
    return next(request);
  }

  const signinReq = request.url.indexOf('auth') >= 0;
  const assetsI18nReq = request.url.indexOf('assets/i18n') >= 0;

  if (environment.useMocks || signinReq || assetsI18nReq) {
    return next(request);
  } else {
    const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);

    if (authToken && refreshToken) {
      const now = new Date().getTime();
      let authData;
      let refreshData;

      try {
        authData = authService.decodeJwt(authToken);
        refreshData = authService.decodeJwt(refreshToken);
      } catch (e) {
        store.dispatch(AuthActions.logout());
        return throwError(() => new Error('Auth or refresh token is incorrect'));
      }

      if ((<any>refreshData).exp * 1000 < now + timeDeltaSubject.value + extraTime) {
        store.dispatch(AuthActions.logout());

        return throwError(() => new Error('Refresh token is expired'));
      } else if ((<any>authData).exp * 1000 < now + timeDeltaSubject.value + extraTime) {
        return httpClient
          .post<Pick<AccessData, 'access' | 'permissions'>>(
            environment.apiHost + 'auth/refresh/',
            { refresh: refreshToken },
          )
          .pipe(
            tap(response => store.dispatch(AuthActions.updateAccessData({ ...response }))),
            switchMap(() => next(request)),
          );
      } else {
        return next(request);
      }
    } else {
      return next(request);
    }
  }
}

function ignoreRefreshToken(request: HttpRequest<any>): boolean {
  return request.context.get(DISABLE_REFRESH_TOKEN_INTERCEPTOR);
}

import { inject, Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { MfeUrlSegments } from '@pt/mfe';
import { EMPTY, filter, map, switchMap, tap } from 'rxjs';
import {
  adaptApiError,
  CookieService,
  DialogService,
  DISABLE_ERROR_INTERCEPTOR,
  ToastService,
  ToastType,
  WINDOW,
} from '@pinup-teams/common';
import { AccessData, Enable2FAData, SigninWithCodeData } from '@pt/models';
import { environment } from '@pt/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngrx/store';
import { createAsyncEffect, RouterActions } from '@pinup-teams/common';
import { RootState } from '@pt/store';
import { AnalyticsService } from '@pt/services';

import {
  AuthService,
  AUTH_TOKEN_KEY,
  GOOGLE_JWT_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  CodesComponent,
  TfaComponent,
  TfaEnableComponent,
} from '../';
import { AuthActions, AuthSelectors } from './';

@Injectable()
export class AuthEffects {
  private readonly _mfeUrlSegments = inject(MfeUrlSegments);
  private readonly _signinSegment = this._mfeUrlSegments.signin;

  /**
   * Effect is used to send analytics event when user is loggin in
   */
  sendAftReq$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.signIn.action),
      tap(({ payload: { token } }) => this._analytics?.sendLoginEvent(token)),
    ),
    { dispatch: false },
  );

  signIn$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.signIn.action),
    switchMap(({ payload: { token }, initiator }) => createAsyncEffect(
      this.signIn(token),
      AuthActions.signIn,
      initiator,
      true,
    )),
  ));

  onSignInSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.signIn.succeededAction),
    tap(({ payload: { token } }) => this._window.localStorage.setItem(GOOGLE_JWT_TOKEN_KEY, token)),
    switchMap(({ payload }) => {
      if (payload.is2faEnabled) {
        this._dialog.open(TfaComponent, { token: payload.token });

        return EMPTY;
      }

      return [AuthActions.enable2fa.action({ payload: { token: payload.token } })];
    }),
  ));

  onSignInFailed$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.signIn.failedAction),
    switchMap(({ error }) => {
      if (error.code === 'user_is_not_found' || error.code === 'user_is_not_active') {
        return [RouterActions.navigateByUrl({ url: '/user-not-found' })];
      }

      this._toast.show({ type: ToastType.Error, message: error.message });

      return [];
    }),
  ));

  enable2fa$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.enable2fa.action),
    switchMap(({ payload: { token }, initiator }) => createAsyncEffect(
      this.enable2fa(token),
      AuthActions.enable2fa,
      initiator,
      true,
    )),
  ));

  onEnable2faSuccess$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.enable2fa.succeededAction),
      tap(({ payload }) => {
        const token = this._window.localStorage.getItem(GOOGLE_JWT_TOKEN_KEY);
        this._dialog.open(TfaEnableComponent, { ...payload, token });
      }),
    ),
    { dispatch: false },
  );

  remove2fa$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.remove2fa.action),
    switchMap(() => createAsyncEffect(this.remove2fa(), AuthActions.remove2fa)),
  ));

  onRemove2faSuccess$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.remove2fa.succeededAction),
      tap(() => this._auth.logout()),
    ),
    { dispatch: false },
  );

  verifyCode$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.verifyCode.action),
    switchMap(({ payload: { token, code }, initiator }) => createAsyncEffect(this.verifyCode(
      token,
      code,
    ), AuthActions.verifyCode, initiator, true)),
  ));

  onVerifyCodeSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.verifyCode.succeededAction),
    concatLatestFrom(() => [this._store.select(AuthSelectors.selectIs2faEnabled)]),
    switchMap(([{ payload }, is2faEnabled]) => {
      if (is2faEnabled) {
        return [AuthActions.updateAccessData(payload), AuthActions.navigateAfter()];
      }

      this._dialog.open(CodesComponent);

      return [AuthActions.updateAccessData(payload)];
    }),
  ));

  onVerifyCodeFailed$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.verifyCode.failedAction),
      filter(({ error }) => error.code === 'throttled'),
      tap(({ error }) => {
        this._toast.show({ type: ToastType.Error, message: `error.${error.code}` });
      }),
    ),
    { dispatch: false },
  );

  resetCode$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.resetCode.action),
    switchMap(({ payload: { token, code }, initiator }) => createAsyncEffect(this.resetCode(
      token,
      code,
    ), AuthActions.resetCode, initiator, true)),
  ));

  onResetCodeSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.resetCode.succeededAction),
    switchMap(({ payload }) => [
      AuthActions.updateAccessData(payload),
      AuthActions.navigateAfter(),
    ]),
  ));

  onResetCodeFailed$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.resetCode.failedAction),
      filter(({ error }) => error.code === 'throttled'),
      tap(({ error }) => {
        this._toast.show({ type: ToastType.Error, message: `error.${error.code}` });
      }),
    ),
    { dispatch: false },
  );

  navigateAfter$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.navigateAfter),
    map(() => {
      const params = new URLSearchParams(this._window.location.search);
      const encodedUrl = params.get('url');
      let url = encodedUrl ? decodeURIComponent(encodedUrl) : null;

      if (url && this._isExternalUrl(url)) {
        this._setRefreshTokenToCookie();

        return RouterActions.navigateByUrl({ url: `/redirect?url=${url}` });
      } else {
        url = url && !url.includes(this._signinSegment)
          ? (url[0] === '/' ? url : '/' + url)
          : '/';

        return RouterActions.navigateByUrl({ url });
      }
    }),
  ));

  updateAccessData$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.updateAccessData),
      tap(({ access, refresh, permissions }) => {
        let allowedPermissions = permissions || [];

        if (access) {
          this._window.localStorage.setItem(AUTH_TOKEN_KEY, access);
        }

        if (refresh) {
          this._window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
        }

        this._setRefreshTokenToCookie();

        if (permissions && environment.allowedPermissions?.length) {
          allowedPermissions = [];
          permissions.forEach(permission => {
            if (environment.allowedPermissions?.includes(permission)) {
              allowedPermissions.push(permission);
            }
          });
        }

        if (allowedPermissions.length) {
          this._permissions.loadPermissions(allowedPermissions);
        }
      }),
    ),
    { dispatch: false },
  );

  logout$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.logout),
    switchMap(() => {
      const { pathname } = this._window.location;
      const path = pathname.includes(this._signinSegment) ? '' : pathname;

      this._permissions.flushPermissions();
      this._window.localStorage.removeItem(AUTH_TOKEN_KEY);
      this._window.localStorage.removeItem(REFRESH_TOKEN_KEY);
      this._window.localStorage.removeItem(GOOGLE_JWT_TOKEN_KEY);

      this._cookie.remove(REFRESH_TOKEN_KEY, environment.cookieDomain);

      return [
        AuthActions.setProfile({ profile: null }),
        RouterActions.navigate({
          route: [`/${this._signinSegment}`],
          extras: { queryParams: { url: !path || path === '/' ? null : encodeURIComponent(path) } },
        }),
      ];
    }),
  ));

  constructor(
    private _actions$: Actions,
    private _auth: AuthService,
    private _cookie: CookieService,
    private _store: Store<RootState>,
    private _http: HttpClient,
    private _permissions: NgxPermissionsService,
    private _toast: ToastService,
    private _dialog: DialogService,
    @Optional() private _analytics: AnalyticsService,
    @Inject(WINDOW) private _window: Window,
  ) {
  }

  signIn(token: string) {
    return this._http
      .post<SigninWithCodeData>(
        environment.apiHost + 'auth/signin/',
        { token },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  enable2fa(token: string) {
    return this._http
      .post<Enable2FAData>(
        environment.apiHost + 'auth/enable/',
        { token },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  verifyCode(token: string, code: string) {
    return this._http
      .post<AccessData>(
        environment.apiHost + 'auth/verify/',
        { token, code },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  resetCode(token: string, code: string) {
    return this._http
      .post<AccessData>(
        environment.apiHost + 'auth/reset/',
        { token, code },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  remove2fa() {
    return this._http.delete<void>(environment.apiHost + 'auth/remove/');
  }

  private _isExternalUrl(url: string): boolean {
    return url.startsWith('http://') || url.startsWith('https://');
  }

  private _setRefreshTokenToCookie() {
    const refresh = this._window.localStorage.getItem(REFRESH_TOKEN_KEY);

    if (refresh) {
      const refreshData: any = this._auth.decodeJwt(refresh);
      const date = new Date(refreshData.exp * 1000).toUTCString();
      this._cookie.set(REFRESH_TOKEN_KEY, refresh, date, environment.cookieDomain);
    }
  }
}

import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { equals, FiltersForm } from '@pinup-teams/common';
import { distinctUntilChanged, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FilterFormsHelperService {
  private readonly _fb = inject(FormBuilder);
  private readonly _router = inject(Router);

  adaptFormToQuery<T>(formValue: T): Record<string, string> {
    return Object.keys(formValue).reduce((result, key) => {
      const value = formValue[key as keyof T];

      return {
        ...result,
        ...(value !== undefined && value !== null && { [key]: value.toString() }),
      };
    }, {} as Record<string, string>);
  }

  adaptQueryToForm<T extends Record<string, string | number> = {
    limit?: number;
    page?: number;
    sort?: string;
  }>(
    query: Record<string, string>,
    defaults: T,
  ): T {
    return Object.keys(query).reduce(
      (result, key) => {
        const value = query[key];

        return {
          ...result,
          ...(value && { [key]: Number(value) || value }),
        };
      },
      { ...defaults },
    );
  }

  generateFiltersForm<T extends object>(initialValue: T): FormGroup<FiltersForm<T>> {
    const controls: FiltersForm<T> = Object.keys(initialValue).reduce((acc, key) => {
      acc[key as keyof T] = new FormControl(initialValue[key as keyof T]);

      return acc;
    }, {} as FiltersForm<T>);

    return this._fb.group(controls) as unknown as FormGroup<FiltersForm<T>>;
  }

  initFormValueChanges$<T>(form: FormGroup, activatedRoute: ActivatedRoute): Observable<T> {
    return form.valueChanges.pipe(
      distinctUntilChanged((prev, curr): boolean => equals(prev, curr)),
      tap((value: T) => {
        this._router.navigate(
          [],
          { queryParams: this.adaptFormToQuery(value), relativeTo: activatedRoute },
        ).then();
      }),
    );
  }

  initUpdateFormValueOnRouteParamsChange$(
    params$: Observable<Params>,
    form: FormGroup,
    callback?: (...arg0: any[]) => void,
  ): Observable<Params> {
    return params$.pipe(
      tap(params => {
        const filters = this.adaptQueryToForm(params, form.value);

        if (callback) {
          callback(filters);
        }

        if (Object.values(filters).some(value => !!value)) {
          form.patchValue(filters, { emitEvent: false });
        }
      }),
    );
  }
}

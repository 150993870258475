import { patchState, signalStoreFeature, withMethods } from '@ngrx/signals';

export function withFiltersUpdate<T extends object>() {
  return signalStoreFeature(
    withMethods(store => ({
      updateFilters(filters: T): void {
        patchState(store, { filters });
      },
    })),
  );
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { getQueryParams } from '@pinup-teams/common';
import { GroupTagEnum } from '@pt/models';
import { Observable } from 'rxjs';
import { environment } from '@pt/environment';

import { getGroupsMock, getLocationsSelectOptionsMock } from './mocks';
import { GetGroupsRes, GetLocationsSelectOptionsRes } from './models';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  private readonly _http = inject(HttpClient);

  getUserGroups(params: {
    tagTypes: GroupTagEnum[] | GroupTagEnum;
  }): Observable<GetGroupsRes> {
    return environment.useMocks
      ? getGroupsMock()
      : this._http.get<GetGroupsRes>(
        `${environment.apiHost}groups/admin/groups-select/`,
        { params: getQueryParams(params) },
      );
  }

  getLocationsSelectOptions(): Observable<GetLocationsSelectOptionsRes> {
    return environment.useMocks
      ? getLocationsSelectOptionsMock()
      : this._http.get<GetLocationsSelectOptionsRes>(
        `${environment.apiHost}user/select-options/locations/`,
      );
  }
}

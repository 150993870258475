<div class="pt-signin__card">
  <svg class="pt-signin__logo">
    <use
      [attr.xlink:href]="'assets/icons.svg#'+logoName"
      [attr.href]="'assets/icons.svg#'+logoName"
    />
  </svg>

  <h1 class="pt-signin__header">
    {{ 'signin.header' | translate }}
  </h1>

  <button
    class="pt-signin__btn-signin-wrap"
    type="button"
    puButton
    size="l"
    uiType="secondary"
  >

    <pu-icon
      iconName="google"
      size="20"
    />
    {{ 'signin.btn.withGoogle' | translate }}

    <div
      id="google-btn-id"
      class="pt-signin__btn-signin"
    >
    </div>

    <div class="pt-signin__btn-overlay">
      <pu-icon
        iconName="google"
        size="20"
      />
      {{ 'signin.btn.withGoogle' | translate }}
    </div>
  </button>
</div>
